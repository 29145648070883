<template>
  <div v-if="renderComponent">
    <Breadcrumb
      v-if="detailTitle"
      :detailTitle="detailTitle.toUpperCase()"
      :parentTitle="parentTitle.toUpperCase()"
    />
    <Breadcrumb v-else detailTitle="&nbsp;" parentTitle="&nbsp;" />
    <div class="row">
      <div class="col-md-2 p-0">
        <div
          class="nav flex-column nav-pills p-0 sticky-block-dxgrid"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <div class="col-12">
            <router-link
              class="btn btn-success w-100"
              :to="{
                name: 'MenuNewSubItem',
                params: {
                  menuId: this.$route.params.menuId,
                  menuItemId: this.$route.params.menuItemId,
                  tabSidebarClick: false,
                },
              }"
            >
              <i class="bi bi-plus"></i>
              {{
                $t(
                  "Menus.MenuItems.NewMenuSubItem",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </router-link>
            <!-- <button
              type="button"
              class="btn btn-danger w-100 mt-2"
              data-bs-toggle="modal"
              data-bs-target="#modalDelete"
            >
              <i class="bi bi-trash"></i>
              {{
                $t(
                  "Buttons.Delete",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button> -->
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <Grid
          :settings="gridSettings"
          :columns="gridColumns"
          :rows="menuItemList"
          @onDetailButtonClick="onDetailButtonClick"
          @changeOrderSuccessful="getMenuItemList"
          @onTranslationButtonClick="onTranslationButtonClick"
        />
      </div>
    </div>
  </div>
  <TranslationModal
    :keyTypeId="keyTypeId"
    :parentPublicId="parentPublicId"
    :formulaName="formulaName"
    :keyName="keyName"
    :keyItemPublicId="keyItemPublicId"
  />
</template>
<script>
import { showModal } from "@/core/helpers/dom";
export default {
  name: "MenuItemListDetail",
  components: {},
  data() {
    return {
      keyTypeId: 7,
      parentPublicId: "",
      formulaName: "",
      keyName: "",
      keyItemPublicId: "",
      gridSettings: {
        action: "MenuItemListDetail",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "menuId",
            value: "menuPublicId",
            isRouteParameter: false,
          },
          {
            key: "menuItemId",
            value: "menuItemId",
            isRouteParameter: true,
          },
          {
            key: "menuSubItemId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-MenuDeleteItem",
        serialNoLink: `#/Menu/Detail/${this.$route.params.menuId}&menuItemId=`,

        allowDragAndDrop: true,
        changeOrderUrl: "/Lcdp-MenuChangeOrder",
        changeOrderModelParentProperties: [
          { key: "parentPublicId", value: "menuId" },
          { key: "parentMenuItemPublicId", value: "menuItemId" },
        ],
        autoOrderProcess: false,

        isGatewayRequest: true,

        buttons: [
          {
            name: "detail",
            cssClass: "btn-primary",
            iconClass: "bi-list-task",
            routeButton: false,
            emitMethodName: "onDetailButtonClick",
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "MenuEditSubItem",
              params: {
                menuId: this.$route.params.menuId,
                menuItemId: this.$route.params.menuItemId,
                menuSubItemId: this.$route.params.menuSubItemId,
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: "Parent Id",
          field: "menuPublicId",
          type: "string",
          visible: false,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.MenuItems.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.MenuItems.Url",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "url",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: this.$store.getters._dateFormat,
          textAlign: "",
        },
      ],
      menuItemList: [],
      parentTitle: "",
      detailTitle: "",
      renderComponent: true,
    };
  },
  methods: {
    onDetailButtonClick(rowData) {
      this.$router.push({
        name: "MenuItemListDetail",
        params: {
          menuId: this.$route.params.menuId,
          menuItemId: rowData.publicId,
        },
      });
    },
    returnMenuItemList() {
      setTimeout(() => {
        this.$router.push({
          name: "MenuListItem",
          params: { menuId: this.$route.params.menuId },
        });
      }, 400);
    },
    forceRerender: function () {
      this.renderComponent = false;

      this.menuItemList = [];

      this.getMenuAndParentDetail();
      this.getMenuItemList();

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    getMenuItemList() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-MenuGetItemChildren?menuId={0}&parentId={1}",
            this.$route.params.menuId,
            this.$route.params.menuItemId
          )
        )
        .then((response) => {
          this.menuItemList = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getMenuAndParentDetail() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-MenuGet?id={0}", this.$route.params.menuId))
        .then((response) => {
          this.parentTitle = response.data.name;
        })
        .catch(function (error) {
          //alert(error);
        });
      if (this.$route.params.menuItemId != null) {
        this.$prodGatewayAxios
          .get(
            String.format(
              "/Lcdp-MenuGetItem?id={0}",
              this.$route.params.menuItemId
            )
          )
          .then((response) => {
            this.detailTitle = response.data.name;
          })
          .catch(function (error) {
            //alert(error);
          });
      }
    },
    onTranslationButtonClick(rowData) {
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      this.parentPublicId = this.$route.params.menuItemId;
      this.keyItemPublicId = rowData.publicId;
      showModal(document.getElementById("translationModal"));
    },
  },
  mounted() {
    this.getMenuAndParentDetail();
    this.getMenuItemList();
    if (this.$store.getters._isMultiLanguage) {
      this.gridSettings.buttons.push({
        name: "translate",
        cssClass: "btn-primary btn-light translation",
        iconClass: "bi bi-translate",
        routeButton: false,
        emitMethodName: "onTranslationButtonClick",
        iconAttributes: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: this.$t(
            "Components.TranslationModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      });
    }
  },
  watch: {
    "$route.params.menuItemId"(newId, oldId) {
      if (newId != oldId) {
        this.forceRerender();
      }
    },
  },
};
</script>
